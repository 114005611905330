<template>
	<!-- 公开公示 -->
	<div class="notice-container">
		<div class="banner-size">
			<div class="banner-limit banner-info">
				<!--        <span class="banner-fMax">信息公示</span>-->
				<!--        <span class="banner-fMin banner-other">公开、公正、透明</span>-->
			</div>
		</div>
		<div class="banner-limit public-announcement mt-20 mb-20" ref="notice">
			<el-tabs v-model="activeName" @tab-click="handleClick" type="card" class="tabs-container">
				<!--子主题组件部分-->
				<SectionHeader :isNotEvolve="isNotEvolve" :title="subTitle" :totalSize="total" placeholder="请输入项目名称"
				 @clickButton="searchByKeyword">
					<div v-show="isNotEvolve" slot="left">
						选择时间
						<el-dropdown @command="handleTime">
							<span class="el-dropdown-link">
								<el-input v-model="timeItem" suffix-icon="el-icon-arrow-down"></el-input>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item,index) in dropdownItems" :key="index" :command="item.name">{{item.name}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</SectionHeader>

				<!--内容部分-->
				<el-tab-pane label="项目公示" name="first">
					<PublicForm class=" " :lists="tableData" :itemType="3"></PublicForm>
				</el-tab-pane>
				<el-tab-pane label="立项公示" name="second">
					<PublicForm class=""></PublicForm>
				</el-tab-pane>
				<el-tab-pane label="项目进展" name="third">
					<div class="el-row--flex">
						<el-input placeholder="请输入项目编号" clearable v-model="keyword" class="long-input">
						</el-input>
						<div class="search-button" @click="searchKeyword"><i class="el-icon-search" color="#fff"></i></div>
					</div>
					<div v-if="userInfo == null" class="showTishi">
						<p>提示：登陆系统后可查看项目进展详细信息,请点击</p>
						<span class="pointer" @click="gotoLogin()">登录</span>
					</div>

				</el-tab-pane>
				
				<el-pagination hide-on-single-page style="margin-top:16px;" layout="total, prev, pager, next" background :total="total"
				 :page-size="pageSize" @current-change="changePageNum">
				 </el-pagination>
			</el-tabs>


		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import PublicForm from "@/components/noticForm"; // 公开公示表单
	import SectionHeader from '@/components/sectionHeader'
	import {
	        Loading
	} from "element-ui";
	export default {
		components: {
			PublicForm,
			SectionHeader,
		},
		computed: {
		  ...mapGetters({
		    // 把 `this.userInfo` 映射为 `this.$store.getters.getUserInfo`
		    userInfo: "getUserInfo",
		  }),
		},
		data() {
			return {
				keyword:'',
				// 是否为立项进展
				isNotEvolve: true,
				// 立项数量
				lixiangNum: 0,
				// 默认选择
				activeName: "first",
				// 公告列表数据
				tableData: [],
				findNoticeName:"",
				subTitle: '项目公示', //子项目的主题名
				totalSize: 0, //获得数据数量
				dropdownItems: [{ //下拉时间选项
					name: '全部',
				},
				{
					name: '2023',
				},
				{
					name: '2022',
				},
				{
					name: '2021',
				},{
					name: '2021',
				}, {
					name: '2020',
				}, {
					name: '2019',
				}, {
					name: '2018',
				}],
				timeItem: '全部',
				year:"",
				total:0, //总量
				pageSize:3,//每页行数
				currentPageNum:"1",//当前页
			};
		},
		mounted() {
			this.getPublicList();
		},
		methods: {
			getPublicList() {
				let loadingNotice = Loading.service({
					target: this.$refs.notice,
				});
				let that = this;
				// type 1项目政策 2通知公告 3公开公示
				let params = {
					pageNum: this.currentPageNum,
					pageSize: this.pageSize,
					type: 3,
					name:this.findNoticeName,
					pubdateQuery:this.year,
				};
				this.$httpApi.get(`/dev-api/document/list`, params).then((res) => {
					console.log("公开公示列表:", res);
					if (res.code === 200) {
						loadingNotice.close()
						that.tableData = res.rows;
						that.lixiangNum = res.total;
						that.totalSize = res.rows.length;
						that.total = res.total
					}

				})
				.catch((err) => {
				  loadingNotice.close()
				});
			},
			/* 公告对应方法 */
			/**
			     * 获取公开公示列表
			        /dev-api/document/list
			     */
			getNoticeList() {
				this.$httpApi.get(`/dev-api/document/list`, {}).then((res) => {
					console.log("公告列表:", res);
					const resData = res.data.rows;
					let target = [];
					for (const el of resData) {
						//  获取每个的详细信息
						this.getNoticeInfo(el.id);
						// target.push(el)
					}
				})
				.catch((err) => {
				  
				});
			},
			/**
			 * 公告详细信息
			 * @param {string} id 传项目id
			 */
			getNoticeInfo(id) {
				if (id) {
					this.$httpApi.get(`/dev-api/document/${id}`, {}).then((res) => {
						console.log("公告详细信息:", res);
					})
					.catch((err) => {
					  
					});
				}
			},

			/**
			 * 切换
			 */
			handleClick(tab, event) {
				console.log("tab:", tab, "event:", event);
				this.subTitle = tab.label;
				
				this.currentPageNum = "1";
				switch (tab.index) {
					case '0':
						this.totalSize = this.lixiangNum;
						this.total = this.lixiangNum;
						this.isNotEvolve = true;
						break;
					case '1':
						this.totalSize = 0;
						this.total = 0
						this.isNotEvolve = true;
						break;
					case '2':
						this.totalSize = 0;
						this.total = 0
						this.isNotEvolve = false;
						break;
					default:
						break;
				}
			},
			/**
			 * 根据关键字查询
			 * @param data
			 */
			searchByKeyword(data) {
				console.log("输入关键字：", data)
				// this.$message({
				// 	showClose: true,
				// 	message: '功能研发中心，请期待',
				// 	type: 'warning'
				// });
				this.findNoticeName = data
				this.getPublicList()
				//发送请求获取数据
			},
			searchKeyword() {
				this.$message({
					showClose: true,
					message: '功能研发中心，请期待',
					type: 'warning'
				});
			},
			/**
			 * 选择时间
			 * @param item
			 */
			handleTime(item) {
				console.log("item:", item)
				if (item == '全部'){
					this.year = ""
				} else {
					this.year = item
				}
				this.timeItem = item
				//发送请求获取数据
				this.getPublicList();
			},
			gotoLogin(){
				this.$router.push({ path: "/login" });
			},
			// 下一页
			changePageNum(current) {
				//current是当前页数
				this.currentPageNum = current
				this.getPublicList()
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/styles/com/theme.scss";

	.notice-container {
		height: 650px;

		.banner-size {
			background: url("../../assets/images/icon_banner_declare.png");
		}

		.banner-info {
			height: 100%;
			display: flex;
			align-items: center;

			.banner-fMax {
				font-size: 40px;
			}

			.banner-other {
				margin-left: 20px;
				font-size: 20px;
			}
		}

		.public-announcement {
			// background: red;
		}

		.el-tabs {
			margin-right: 10px;
			.el-tabs__header {

				// background: #333;
				.el-tabs__nav {
					height: 70px;
					border: 0;
				}

				.el-tabs__item {
					height: 26px;
					font-weight: 700;
					// color:#2d81ff;
					font-size: 20px;
					line-height: 70px;
					
				}

				.el-tabs__active-bar {
					width: 81px;
					height: 5px;
					background-color: #2d81ff;
				}
			}
		}

		.el-input {
			width: 120px;
			height: 32px;
			margin-left: 10px;

			.el-input__inner {
				height: 32px;
				border-radius: 0px;
			}
		}
		.long-input{
			width: 600px;
		}
		.el-tabs__header {
			position: absolute;
			top: 10px;
		}

		.tabs-container {
			position: absolute;
			margin-top: -80px;
		}

	}

	.el-dropdown-menu {
		width: 120px;
	}
</style>

<style lang="scss">
.notice-container {
	.el-tabs__nav{
		border: 0 !important;
	}

  .el-tabs__header{

  }

  .el-tabs__item{
    width: 300px;
    height: 3.75rem;
    line-height: 3.75rem;
    font-size: 20px;
    background: rgba(#fff, .4);
    color: #fff;
    text-align: center;
    /*margin-right: 10px;*/

  &:hover {
     background: #fff;
     color: #000;
     border-bottom: 0;
   }
  }

  .is-active {
    background: #fff;
    color: #000;
    border-bottom: 0;
  }
	.el-tabs__item {
		width: 393px;
		height: 3.75rem;
		line-height: 3.75rem;
		font-size: 20px;
		background: rgba(#fff, .4);
		color: #fff;
		text-align: center;
		margin-right: 10px;
		border: 0 !important;
		&:hover {
			background: #fff;
			color: #000;
			border-bottom: 0;
		}
	}

	.is-active {
		background: #fff;
		color: #000;
		border-bottom: 0;
	}

	.search-button {
		width: 40px;
		height: 40px;
		border-radius: 0px;
		border: 0px;
		background-color: rgb(69, 141, 252);
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.showTishi{
		display: flex;
		padding: 0 10px;
		margin-top: 20px;
		span{
			color: rgb(69, 141, 252);
		}
	}
}
</style>
