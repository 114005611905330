<template>
	<div class="section-container">
		<div class="section-header">

			<div v-if="isNotEvolve" class="el-row--flex">
				<el-input :placeholder="placeholder" class="toInput" clearable v-model="keyword">
				</el-input>
				<div class="search-button" @click="searchByKeyword"><i class="el-icon-search" color="#fff"></i></div>
			</div>
			<div v-if="isNotEvolve" class="order-right">
				<slot name="right">共{{totalSize}}条记录</slot>
			</div>
		</div>
		<div class="order-select">
			<div>
				<slot name="left"></slot>
			</div>
			<div>
				<slot name="middle"></slot>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '',
			};
		},
		props: {
			isNotEvolve: {
				type: Boolean,
				default: true,
			},
			title: {
				type: String,
				default: ''
			},
			totalSize: {
				type: Number,
				default: 0,
			},
			placeholder: {
				type: String,
				default: '',
			}
		},
		mounted() {},
		methods: {
			/**
			 * 将搜索输入框中的数据发送给父组件
			 */
			searchByKeyword() {
				this.$emit('clickButton', this.keyword)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.my-input{
	}
	.section-container {
		margin-bottom: 30px;
	}

	.section-header {
		padding: 10px;
		border-bottom: 2px solid #333333;
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
	}

	.section-title {
		font-size: 24px;
		color: rgb(13, 28, 40);
		font-weight: 600;
		height: 46px;
		line-height: 46px;
	}


	.order-select {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 20px;
	}
	
	.order-right {
		font-size: 14px;
		color: #828282;
		width: 260px;
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}

	.search-button {
		width: 40px;
		height: 40px;
		border-radius: 0px;
		border: 0px;
		margin-left: 0px;
		background-color: $col-theme;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	  /* 利用穿透，设置input边框隐藏 */
	 .toInput{
		 width: 320px;
		  border-radius: 0px !important;
		 .el-input__inner {
		    border-radius: 0px !important;
			input {
			  background-color: #FFFFFF;
			  border-radius: 0px !important;
			}
		  }
	 }
</style>
<style lang="scss">
	// 全局修改input样式 进行全局污染
	.el-input__inner {
	   border-radius: 0px !important;
				input {
				  background-color: #FFFFFF;
				  border-radius: 0px !important;
				}
	 }
</style>
