<template>
  <el-table :data="lists" style="width: 100%" @row-click="todo">
    <el-table-column  label="状态" width="120" align="center">
      <div class="showStatu">
		<span>【公示中】</span>
		</div>
    </el-table-column>
    <el-table-column prop="name" label="标题" min-width="540" align="left"> </el-table-column>
    <el-table-column prop="areaname" label="所属区域" width="200" align="center">
    </el-table-column>
    <el-table-column prop="policypubdate" label="公示时间" width="220" align="right">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "noticForm",
  props: {
    lists: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //关注需要传此类型
    itemType:{
      type:Number,
      default: function () {
        return 0;
      },
    }
  },
  data() {
    return {};
  },
  methods: {
    todo(val) {
      let that = this;
      console.log("点击,转跳", val);
      this.$router.push({
        path: "/notice/detail/" + val.id+"?type="+that.itemType
      });
    },
    /* 暴露组件方法 */
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
/* 标题 */
.el-table__header-wrapper {
  .is-leaf {
    background: $bc-lightGrey;
  }
}
/* 内容 */
.el-table__body-wrapper {
  /* 状态栏 */
  // .el-table_1_column_1 {
  //     color: $color-danger !important;
  // }
  /* 横向属性 */
  .el-table__row {
    height: 60px;
    cursor: pointer;
    &:hover {
      // height: 70px;
      // font-size: $font-size18;
      font-weight: bold;
      .el-table_1_column_1 {
        color: $color-primary !important;
      }
	  .el-table_1_column_3 {
	    color: $color-primary !important;
	  }
	  .el-table_1_column_4 {
	    color: $color-primary !important;
	  }
	  .el-table_1_column_5 {
	    color: $color-primary !important;
	  }
    }
  }
}
.text-red {
  color: $color-danger;
}
.showStatu{
  color: $color-danger;
}
</style>
